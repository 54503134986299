import React from 'react';
import './Cards.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Cards() {
  return (
    <div className='cards'>
      <h1>Bendrijos naujienos</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <Card sx={{
              minWidth: 225,
              width: 300,
              textAlign: 'center',
              background: 'linear-gradient(to bottom, #ffffff, #f0f8ff)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              border: '2px solidrgb(0, 0, 0)',
              borderRadius: '12px'
            }}>
              <CardContent>
                <Typography variant="h4" component="div" sx={{
                  color: '#black',
                  fontWeight: 'bold',
                  marginBottom: 2,
                  fontFamily: '"Playfair Display", serif'
                }}>
                  2025 m. balandžio 11 dieną 18 val.
                </Typography>

                <Typography variant="body1" sx={{
                  lineHeight: 1.6,
                  textAlign: 'center',
                  color: '#2c5530',
                  marginBottom: 2,
                  fontFamily: '"Roboto", sans-serif'
                }}>
                  SB „Piliakalnis“ vandenvietės teritorijoje organizuojamas visuotinis sodininkų
                </Typography>
                <Typography variant="h4" component="div" sx={{
                  color: '#black',
                  fontWeight: 'bold',
                  marginBottom: 2,
                  fontFamily: '"Playfair Display", serif'
                }}>
                  SUSIRINKIMAS
                </Typography>

                <div style={{
                  textAlign: 'center',
                  marginTop: 3,
                  marginBottom: 2,
                }}>
                  <Typography variant="body1" sx={{ marginBottom: 1, textAlign: 'left', }}>
                    <strong>Darbotvarkė:</strong>
                    <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
                      <li>Valdybos ataskaita už 2024 m.</li>
                      <li>Finansinė ataskaita už 2024 m.</li>
                      <li>2025 m. darbų ir finansų sąmatos tvirtinimas.</li>
                      <li>Einamieji klausimai.</li>
                    </ul>
                  </Typography>
                  <Typography variant="body2" sx={{
                    marginTop: 2,
                    color: '#666'
                  }}>
                    Sodų bendrijos "Piliakalnis" valdyba
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
